:root {
    --max-width: 1100px;
    --border-radius: 12px;
    --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
      "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
      "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    --primary-glow: conic-gradient(
      from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg
    );
    --secondary-glow: radial-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );

    --tile-start-rgb: 239, 245, 249;
    --tile-end-rgb: 228, 232, 233;
    --tile-border: conic-gradient(
      #00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080
    );

    --callout-rgb: 238, 240, 241;
    --callout-border-rgb: 172, 175, 176;
    --card-rgb: 180, 185, 188;
    --card-border-rgb: 131, 134, 135;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --foreground-rgb: 255, 255, 255;
      --background-start-rgb: 0, 0, 0;
      --background-end-rgb: 0, 0, 0;

      --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
      --secondary-glow: linear-gradient(
        to bottom right,
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0.3)
      );

      --tile-start-rgb: 2, 13, 46;
      --tile-end-rgb: 2, 5, 19;
      --tile-border: conic-gradient(
        #ffffff80,
        #ffffff40,
        #ffffff30,
        #ffffff20,
        #ffffff10,
        #ffffff10,
        #ffffff80
      );

      --callout-rgb: 20, 20, 20;
      --callout-border-rgb: 108, 108, 108;
      --card-rgb: 100, 100, 100;
      --card-border-rgb: 200, 200, 200;
    }
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-family: "Quicksand", sans-serif!important;
  }

  body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(
        to bottom,
        transparent,
        rgb(var(--background-end-rgb))
      )
      rgb(var(--background-start-rgb));
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (prefers-color-scheme: dark) {
    html {
      color-scheme: dark;
    }
  }



  /* Định dạng cơ bản cho trang */
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    color: white;
  }

  .header .logo {
    font-size: 24px;
  }

  .header nav a {
    margin-right: 20px;
    color: white;
    text-decoration: none;
  }

  .header .cta {
    padding: 10px 20px;
    background-color: #0099ff;
    border: none;
    color: white;
    cursor: pointer;
  }

  .hero {
    background: url('https://ik.imagekit.io/s7qfxbeum/Banner%20bu%CC%9Bo%CC%9B%CC%81c%20%C4%91a%CC%82%CC%80u%20tie%CC%82n%20cu%CC%80ng%20nike%204.jpg?updatedAt=1726323017509');
    background-size: cover;
    color: white;
    text-align: center;
    padding: 100px 20px;
    min-height: 85vh;
  }

  .hero h1 {
    font-size: 48px;
  }
  .titlepage{
    color: #000000;
    font-size: 32px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  .titlepage span{
    color: #2196F3;
  }

  .hero .cta {
    margin-top: 20px;
    padding: 15px 30px;
    background-color: #0099ff;
    border: none;
    color: white;
    cursor: pointer;
  }

  .about-us, .features, .product-showcase, .testimonials, .faq, .cta-section, .footer {
    padding: 30px 0;
    text-align: center;
  }

  .products {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .product {
    margin: 20px;
    text-align: center;
  }

  .product img {
    max-width: 100%;
    height: auto;
  }

  .footer .social-links a {
    margin: 0 10px;
    color: #333;
    text-decoration: none;
  }
  .listshop{
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .listshop li{
    max-width: 380px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    background: #F2F2F2;
    padding: 20px;
    border-radius: 15px;
    /* box-shadow: 1px 0px 8px 4px #dad8d833; */
  }
  .listshop li img{
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    max-height: 275px;
    overflow: hidden;
    object-fit: cover;
  }
  .listshop li h2{
    font-size:20px;
    color:#000000;
    text-align: left;
    margin-bottom: 20px;
  }
  .listshop li .scprice{
      color: #03A9F4;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  }
  .listshop li .scprice button{
    font-size: 14px;
    padding: 6px 15px;
    border-radius: 20px;
    border: navajowhite;
    background: #2196F3;
  }

  .line-guest{
    max-width: 380px;
    height: 200px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
  }
  .img-guest{
    height: 100%;
  }

  .line-guest img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .faq{
    padding: 50px 0;
    margin-bottom: 2rem;
  }





@media (max-width: 770px) {
    .titlepage{
        font-size: 24px;
    }
    .listshop li{
        margin-bottom: 10px;
        max-width: 345px;
    }
    .hero{
        background-position: top;
    }

}